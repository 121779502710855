
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "9b2cc465-7f59-4d5e-8030-a3b0d399c5cf"
  },
  "ui": {
    "primary": "green",
    "gray": "cool",
    "colors": [
      "red",
      "emerald",
      "pink",
      "error",
      "cadet-blue",
      "amber",
      "purple",
      "none",
      "primary"
    ],
    "strategy": "merge"
  },
  "_nuxtWebFrameworkModuleOptions": {
    "projects": {
      "default": "<your_project_id>"
    },
    "frameworksBackend": {
      "invoker": "public"
    }
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/runner/work/proper-plan/proper-plan/app.config.ts"
import cfg1 from "/home/runner/work/proper-plan/proper-plan/node_modules/@hiyield/nuxt-layer-firebase/app.config.ts"
import cfg2 from "/home/runner/work/proper-plan/proper-plan/node_modules/@hiyield/nuxt-layer-firebase-auth/app.config.ts"
import cfg3 from "/home/runner/work/proper-plan/proper-plan/node_modules/@hiyield/nuxt-layer-firebase/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, inlineConfig)
